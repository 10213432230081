<template>
    <admin-dashboard-layout :loading="loading">
        <v-container>
            <v-form>
                <v-card class="mx-15 px-15">
                    <v-card-title>
                        <v-row>
                            <v-col v-text="industry.name"/>
                            <v-col class="text-right">
                                <v-btn small :to="{name: 'admin.industries.index'}" v-text="'Return'"/>
                            </v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-row>
                                <v-col>
                                    <v-text-field outlined dense label="Name"  v-model="industry.name"></v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="text-center">
                                    <v-btn v-if="$route.params.id" @click="handleDelete" color="red" v-text="'Delete'"/>
                                </v-col>
                                <v-col class="text-center">
                                    <v-btn @click="handleSave" v-text="'Save'"/>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-form>
        </v-container>
    </admin-dashboard-layout>
</template>

<script>

import Industry from "@/models/Industry";
import AdminDashboardLayout from "@/layouts/AdminDashboardLayout";
import {mapMutations} from "vuex";

export default {
    name: "Form",
    components: {AdminDashboardLayout},

    data: function () {
        return {
            industry: {
                name: ''
            },
            loading: false
        }
    },

    async mounted() {
        this.loading = true;
        const id = this.$route.params.id
        if (id) {
            this.industry = await Industry.find(id)
        }
        this.loading = false;
    },

    methods: {
        async handleSave() {
            const industry = new Industry(this.industry);
            this.industry = await industry.save();
            this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'});

            if (this.industry.id && !this.$route.params.id) {
                await this.$router.push({name: 'admin.industries.show', params: {id: this.industry.id}})
            }
        },

        async handleDelete() {
            let industry = await Industry.find(this.$route.params.id)
            const isDeleted = await industry.delete()
            this.showSnackBar({color: 'success', timeout: 3000, text: 'Saved successfully'})

            if (isDeleted) {
                this.$router.push({name: 'admin.industries.index'})
            }
        },
        ...mapMutations(['showSnackBar']),
    },

}
</script>
